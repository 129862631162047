import styled from 'styled-components'
import { grayscale, orange } from 'src/styles/colors'

export const Container = styled.div`
  width: 380px;
  margin: auto;

  h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${grayscale[500]};
  }
  p{
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${grayscale[400]};
  }
  hr {
    border-color: ${grayscale[200]};
  }
`

export const Content = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 40px;
`

export const BoxButton = styled.div`
  border: 1px solid #FFB46E;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;
  &:hover{
    opacity: 0.9;
  }

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${orange.extra};
    margin: 0;
  }
`

export const ContainerQrCode = styled.div`
  width: 380px;
  margin: auto;
  position: relative;

  .icon-arrow{
    position: absolute;
    top: -60px;
    left: -60px;
    cursor: pointer;

    img{
      width: 100%;
    }
  }

  h3{
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${grayscale[500]};
  }
  p{
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${grayscale[400]};
  }
  hr {
    border-color: ${grayscale[200]};
  }

  img{
    width: 220px;
  }
`

export const ContentImage = styled.div`
  text-align: center;
  margin-top: 40px;
`

export const BoxCodeIOS = styled.div`
  p{
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: ${grayscale[500]};
    margin: 0;
  }
`

export const BoxCodePlay = styled.div`
  p{
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: ${grayscale[500]};
    margin: 0 0 20px 0;
  }
`
