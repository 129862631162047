import React from 'react'
import { Section, Title, Description, Link, Container, Content } from './style'
import BreadCrumb from 'src/components/Breadcrumb'

const Hero = () => {
  return (
    <Section id='private-payroll-header'>
      <Container>
        <Content>
          <BreadCrumb
            sectionName='Conheça o novo empréstimo consignado para CLT'
            type='Pra você'
            text='Empréstimo consignado privado'
            link='/pra-voce/emprestimos/emprestimo-consignado-privado/'
          />
          <Title>Conheça o novo empréstimo consignado para CLT</Title>
          <Description>
            Aproveite a oportunidade com o Crédito Trabalhador no novo lançamento do Governo Federal! Escolha o Inter e receba a melhor oferta para você com <strong>menores taxas e parcelas reduzidas</strong> para funcionários de carteira assinada. Confira aqui como contratar!
          </Description>
          <Link href='#worker-credit'>
            Conheça como contratar
          </Link>
        </Content>
      </Container>
    </Section>
  )
}

export default Hero
