import React, { useState } from 'react'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { SideModal } from '@interco/inter-ui/components/Modal/SideModal'

import IconGooglePlay from '../../assets/images/icon-google-play.svg'
import IconAppleStore from '../../assets/images/icon-apple-store.svg'
import QrCodePlay from '../../assets/images/qrcodePlay.png'
import QrCodeIOS from '../../assets/images/qrcodeIOS.png'
import IconArrow from '../../assets/images/icon-arrow.svg'

import * as S from './style'

const typeSelect = {
  IOS: 'IOS',
  PLAY: 'PLAY',
}

export const ModalQRcode = ({ visible, onClose }: {visible: boolean; onClose: () => void}) => {
  const [ select, setSelect ] = useState('')
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <SideModal
      visible={visible}
      onClose={onClose}
      onClickOutside={onClose}
      direction='right'
    >
      {select ? (
        <S.ContainerQrCode>
          <div className='icon-arrow' onClick={() => setSelect('')}>
            <img src={IconArrow} alt='icon arrow' />
          </div>

          <h3>Acesse o app da Carteira de Trabalho Digital</h3>
          <hr />
          <p>Aponte a câmera do celular para o QR Code abaixo e simule a melhor condição pra você!</p>
          <S.ContentImage>
            {select === typeSelect.IOS && (
              <S.BoxCodeIOS>
                <p>AppStore (IOS)</p>
                <img src={QrCodeIOS} alt='Qrcode IOS' />
              </S.BoxCodeIOS>
            ) }

            {select === typeSelect.PLAY && (
              <S.BoxCodePlay>
                <p>Google Play (Android)</p>
                <img src={QrCodePlay} alt='Qrcode Google Play' />
              </S.BoxCodePlay>
            ) }

          </S.ContentImage>

        </S.ContainerQrCode>
      ) : (
        <S.Container>
          <h3>Acesse o app da Carteira de Trabalho Digital</h3>
          <hr />
          <p>Selecione o sistema operacional do seu celular</p>
          <S.Content>
            <S.BoxButton onClick={() => {
              setSelect(typeSelect.PLAY)
              sendDatalayerEvent({
                section: 'dobra_02',
                element_action: 'click button',
                element_name: 'Acessar Google Play',
                section_name: 'Como contratar o Crédito Trabalhador?',
              })
            }}
            >
              <p>Acessar Google Play</p>
              <img src={IconGooglePlay} alt='icon google' />
            </S.BoxButton>
            <S.BoxButton onClick={() => {
              setSelect(typeSelect.IOS)
              sendDatalayerEvent({
                section: 'dobra_02',
                element_action: 'click button',
                element_name: 'Acessar AppStore',
                section_name: 'Como contratar o Crédito Trabalhador?',
              })
            }}
            >
              <p>Acessar AppStore</p>
              <img src={IconAppleStore} alt='Icon apple' />
            </S.BoxButton>
          </S.Content>
        </S.Container>
      )}
    </SideModal>
  )
}
