import React, { useState } from 'react'
import * as S from './style'
import Pictographic from '../../assets/images/Pictographic.png'
import MockupCelular from '../../assets/images/background-celular.png'
import { ModalQRcode } from '../../components/modal/_index'
import ModalMobile from '../../components/ModalMobile/_index'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import useWidth from "src/hooks/window/useWidth"
import { WIDTH_MD } from "src/styles/breakpoints"

const advantages = [
  "Acesse o Aplicativo da Carteira Digital de Trabalho",
  "Selecione a opção “Empréstimos” na barra inferior do app",
  "Escolha em seguida qual contrato de trabalho ativo você usará para contratar",
  "Faça uma simulação do empréstimo, selecionando valor e parcela desejados",
  "Solicite uma proposta e aguarde o retorno através de uma notificação",
  "Analise a proposta do Inter e siga os passos seguintes para contratar!",
]

const ContentAdvantages = () => {
  return (
    <S.BoxAdvantages>
      { advantages.map((advantage: string, index: number) => (
        <S.CardAdvantages key={advantage}>
          <span>{index + 1}</span>
          <p>{advantage}</p>
        </S.CardAdvantages>
      )) }
    </S.BoxAdvantages>
  )
}

const ContentVideo = () => (
  <S.ContentCell>
    <img src={Pictographic} alt='Pictographic' className='pictographic' />
    <img src={MockupCelular} alt='MockupCelular' className='mockupCelular' />

    <div className='boxvideo'>
      <video width='320' height='700' autoPlay loop muted>
        <source src='https://central-imagens.bancointer.com.br/gif/gif-consignado-privado-1/arquivo.webm' type='video/webm' />
        Seu navegador não suporta o elemento de vídeo.
      </video>
    </div>
  </S.ContentCell>
)

const ContentTitle = () => (
  <S.Title>Como contratar o Crédito Trabalhador?</S.Title>
)

const ContentDescription = () => (
  <S.Description>
    O novo sistema de crédito consignado para os trabalhadores CLT é uma iniciativa do Governo Federal e pode ser contratado diretamente do app da Carteira de Trabalho Digital.
  </S.Description>
)

type ContentButtonProps = {
  openModal: () => void;
}

const ContentButton = ({ openModal }: ContentButtonProps) => (
  <S.BoxButton>
    <button onClick={openModal}>Acessar App Carteira de Trabalho Digital</button>
  </S.BoxButton>
)

const WhatAdvantages = () => {
  const [ visible, setVisible ] = useState<boolean>(false)
  const [ visibleMobile, setVisibleMobile ] = useState<boolean>(false)
  const [ sendDatalayerEvent ] = useDataLayer()

  const width = useWidth()

  const toggleModal = () => {
    if (width < WIDTH_MD) {
      setVisibleMobile(!visibleMobile)
    } else {
      setVisible(!visible)
    }

    sendDatalayerEvent({
      section: 'dobra_02',
      element_action: 'click button',
      element_name: 'Acessar App Carteira de Trabalho Digital',
      section_name: 'Como contratar o Crédito Trabalhador?',
    })
  }

  return (
    <S.Section id='worker-credit'>
      <ModalQRcode onClose={toggleModal} visible={visible} />
      <ModalMobile isVisible={visibleMobile} onClose={toggleModal} />

      <S.ContainerMobile>
        <ContentTitle />
        <ContentVideo />
        <ContentDescription />
        <ContentAdvantages />
        <ContentButton openModal={toggleModal} />
      </S.ContainerMobile>

      <S.Container>
        <S.ContentImage>
          <ContentVideo />
        </S.ContentImage>
        <S.ContentInfo>
          <ContentTitle />
          <ContentDescription />
          <ContentAdvantages />
          <ContentButton openModal={toggleModal} />
        </S.ContentInfo>
      </S.Container>

    </S.Section>
  )
}

export default WhatAdvantages
