import { device } from 'src/styles/breakpoints'
import { grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${grayscale[100]};

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/background-ecp/image.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    min-height: 460px;        
  }

  @media ${device.desktopLG} {
    min-height: 553px;        
  }

  @media ${device.desktopXXL} {
    min-height: 547px;        
  }
`

export const Container = styled.div`
  padding: 40px 24px;
  @media ${device.tablet}{
    padding: 44px;
  }
  @media ${device.desktopLG}{
    padding: 64px 44px;
  }
  @media ${device.desktopXXL}{
    padding: 96px 0;
    width: 1150px;
    margin: auto;
  }
`

export const Content = styled.div`
  .bread-crumb {
    display: none;
  }

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    width: 336px;

    .bread-crumb {
      display: block;

      span {
        font-weight: bold !important;
        color: ${orange.extra} !important;
        opacity: 1;
      }
      a {
        font-weight: 400 !important;
        color: ${grayscale[500]};
        opacity: 0.8;
      }
    }
  }

  @media ${device.desktopLG} {
    width: 450px;

    .bread-crumb span, 
    .bread-crumb a{
      font-size: 16px;
    }
  }

  @media ${device.desktopXXL} {
    width: 556px;
  }
`

export const Title = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: normal;
  letter-spacing: 0px;
  color: ${grayscale[500]};
  margin: 0 0 24px 0;

  @media ${device.tablet}{
    margin: 0 0 16px 0;
  }

  @media (min-width: 1024px){
    margin: 24px 0 16px 0;
    font-size: 40px;
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: normal;
  letter-spacing: 0px;
  color: ${grayscale[400]};
  margin: 0;

  @media ${device.tablet}{
    color: ${grayscale[500]};
  }

  @media (min-width: 1024px){
    font-size: 18px;
  }

  @media ${device.desktopLG}{
  }
`

export const Link = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  letter-spacing: 0px;

  background-color: ${orange[500]};
  color: ${white};

  height: 48px;
  width: 100%;
  border-radius: 8px;
  margin-top: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover{
    opacity: 0.9;
    color: ${white};
  }
`
