import React, { MouseEvent } from 'react'
import * as S from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import IconGooglePlay from '../../assets/images/icon-google-play.svg'
import IconAppleStore from '../../assets/images/icon-apple-store.svg'

interface IModalMobileProps {
  isVisible: boolean;
  onClose: () => void;
}

const ModalMobile = ({ isVisible, onClose }: IModalMobileProps) => {
  const [ sendDatalayerEvent ] = useDataLayer()

  const handleClose = (e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
    if (e.currentTarget === e.target || (e.target as HTMLElement).className.includes('close-button')) {
      onClose()
    }
  }

  return (
    isVisible && (
      <S.Container className='modal-overlay' onClick={handleClose}>
        <S.Card className='modal-card'>
          <S.BoxClose>
            <S.Close className='close-button'>Fechar</S.Close>
            <p className='title'>Acesse o app da Carteira de Trabalho Digital</p>
          </S.BoxClose>
          <S.Content>
            <S.BoxButton
              target='_blank'
              rel='noopener noreferrer'
              href='https://play.google.com/store/apps/details?id=br.gov.dataprev.carteiradigital&hl=pt_BR'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Acessar Google Play',
                  section_name: 'Como contratar o Crédito Trabalhador?',
                })
              }}
            >
              <p>Acessar Google Play</p>
              <img src={IconGooglePlay} alt='Icon Google Play' />
            </S.BoxButton>
            <S.BoxButton
              target='_blank'
              rel='noopener noreferrer'
              href='https://apps.apple.com/br/app/carteira-de-trabalho-digital/id1295257499'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  element_action: 'click button',
                  element_name: 'Acessar AppStore',
                  section_name: 'Como contratar o Crédito Trabalhador?',
                })
              }}
            >
              <p>Acessar AppStore</p>
              <img src={IconAppleStore} alt='Icon apple' />
            </S.BoxButton>
          </S.Content>
        </S.Card>
      </S.Container>
    )
  )
}

export default ModalMobile
