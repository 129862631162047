import { grayscale, orange } from 'src/styles/colors'
import styled, { keyframes } from 'styled-components'

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 999999;
`

export const Card = styled.div`
 width: 100%;
  max-height: 230px;
  background: white;
  padding: 30px 20px 20px 20px;
  box-shadow: 0 -5px 15px rgba(0, 0, 0, 0.3);
  border-radius: 10px 10px 0 0;
  animation: ${slideUp} 0.2s ease-out;
  position: relative;

  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${grayscale[500]};
  }
`

export const Close = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  font-weight: 600;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0px;

  color: ${orange[500]};
`

export const BoxClose = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;

  p {
    margin: 0;
  }
`

export const Content = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  margin-top: 16px;
`

export const BoxButton = styled.a`
  border: 1px solid #FFB46E;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  cursor: pointer;

  p {
    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${orange.extra};
    margin: 0;
  }
`
