import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const CardContainer = styled.div`
  background: white;
  padding: 16px 16px;
  align-items: center;
  border-radius: 16px;
  min-height: 132px;

  p {
    margin-left: 16px;
  }

  circle {
    stroke-width: 2.5;
  }

  rect {
    stroke-width: 2.5;
  }

  path {
    stroke-width: 2.5;
  }
`

export const Section = styled.section`
  .advantages-mobi {
    display: block;
  }

  .advantages-desk {
    display: none;
  }

  .box-advantages-mobi{
    height: 100%;
    > div {
      min-height: 100%;
    }
  }

  @media ${device.tablet} {
    .advantages-mobi {
      display: none;
    }

    .advantages-desk {
      display: flex;
    }
  } 

  @media ${device.desktopXXL}{
    .container{
      max-width: 1152px;
    }
  }

  @media ${device.desktopXXXL}{
    .container{
      max-width: 1152px;
    }
  }

`
