import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import pageContext from './pageContext.json'

import { Wrapper } from './style'

import Hero from './sections/header/_index'
import WhatAdvantages from './sections/WhatAdvantages/_index'
import HowToUse from './sections/HowToUse/_index'
import FAQ from './sections/Faq/_index'

function EmprestimoConsignadoPrivado () {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <WhatAdvantages />
        <HowToUse />
        <FAQ faq={pageContext.structuredData.faq} />
      </Layout>
    </Wrapper>
  )
}

export default EmprestimoConsignadoPrivado
