import { device } from 'src/styles/breakpoints'
import { gray, grayscale, orange, white } from 'src/styles/colors'
import styled from 'styled-components'

export const Section = styled.div`
  overflow: hidden;
  padding: 40px 24px;

  @media ${device.tablet} {
    padding: 44px;
  }

  @media ${device.desktopLG} {
    padding: 64px;
  }
  
  @media ${device.desktopXL} {
    padding: 90px 0 40px 0;
  }
`

export const Container = styled.div`
  display: none;

  @media ${device.tablet} {
    display: block;
    max-width: 720px;
    margin: auto;

    display: flex;
    flex-direction: row;
    gap: 80px;
  }

  @media ${device.desktopLG} {
    max-width: 960px;
  }

  @media ${device.desktopXL} {
    max-width: 1152px;
  }
`

export const ContainerMobile = styled.div`
  display: block;
  max-width: 540px;
  margin: auto;

  @media ${device.tablet} {
    display: none;
  }
`

export const ContentImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${device.tablet} {
    flex-basis: 45%
  }
  @media ${device.desktopXL} {
    height: 800px;
  }
`

export const ContentInfo = styled.div`
  @media ${device.tablet} {
    flex-basis: 55%
  }
  @media ${device.desktopXL} {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`

export const ContentCell = styled.div`
  position: relative;
  height: 359px;
  width: 175px;
  margin: 24px auto;
  display: flex;
  align-items: center;
  justify-content: center;

  .mockupCelular{
    z-index: 3;
    width: 185px;
    height: 320px;
    position: absolute;
  }

  .pictographic {
    position: absolute;
    bottom: 5px;
    left: -15px;
    z-index:  4;
  }

  .boxvideo {
    position: absolute;
    z-index: 2;
    height: 297px;
    width: 165px;
    border-radius: 30px;
    overflow: hidden;

    video {
      width: 100%;
      height: auto;
    }
  }

  @media ${device.tablet} {
    margin-left: 28px;

    .pictographic {
      bottom: -40px;
      left: -50px;
    }
    .mockupCelular{
      width: 250px;
      height: 417px;
    }

    .boxvideo {
      height: 390px;
      width: 215px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media ${device.desktopLG} {
    height: 450px;
    width: 230px;

    .pictographic {
      bottom: -50px;
      left: -50px;
    }
    .mockupCelular{
      width: 350px;
      height: 550px;
    }

    .boxvideo {
      width: 300px;
      height: 505px;
    }
  }

  @media ${device.desktopXL} {
    height: 700px;
    width: 450px;

    .pictographic {
      bottom: -40px;
      left: -10px;
      width: 70px;
      height: 70px;
    }
    .mockupCelular{
      width: 467px;
      height: 770px;
    }

    .boxvideo {
      width: 400px;
      height: 720px;
    }
  }

  @media ${device.desktopXXL} {
    margin-left: 0;
  }
`

export const BoxAdvantages = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`

export const CardAdvantages = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 22px;

  span {
    font-weight: 600;
    font-size: 14.09px;
    line-height: 18.12px;
    letter-spacing: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${white};
    background-color: ${orange.extra};
    height: 32px;
    width: 32px;
    border-radius: 50%;
    padding: 15px;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0px;
    color: ${grayscale[400]};
    margin: 0;
  }

  @media ${device.desktopLG} {
    p{
      font-size: 16px;
    }
  }
`

export const Title = styled.h2`
  font-weight: 600;
  font-size: 32px;
  line-height: 100%;
  letter-spacing: 0px;
  color: ${grayscale[500]};
  
  @media ${device.tablet} {
    margin-bottom: 16px;    
  }

  @media ${device.desktopLG} {
    font-size: 40px;
  }
`

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0px;
  color: ${gray[700]};

  @media ${device.tablet} {
    font-size: 14px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
  }
`

export const BoxButton = styled.div`
  margin-top: 40px;

  button {
    background-color: ${orange.extra};
    color: ${white};

    font-weight: 700;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0px;
    text-align: center;

    height: 48px;
    border-radius: 8px;
    width: 100%;
    cursor: pointer;

    &:hover {
      color: ${white};
      opacity: 0.9;
    }
  }
`
